import styles from "./SelectSharedPickupPoint.module.scss";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Typography } from '@mui/material';
import { PickupPoint } from "../../../shared/map/models";
import { PluginStore } from "react-pluggable";
import { PluginEvent } from "../../Extensions/pluginEvent";
import { getAvailablePickupPointsForWebshopOrder } from "../../../components/client-panel/components/my-account-info/services";
import { CartItem } from "../../../components/cart/models";
import { getCartItems } from "../../../components/cart/services";

interface SelectPickupPointProps {
    pluginStore: PluginStore;
}

export default function SelectSharedPickupPointComponent({ pluginStore }: SelectPickupPointProps) {
    const defaultValue = localStorage.getItem("pickupPointsFilter") ? localStorage.getItem("pickupPointsFilter")?.toString() : "any";
    const disabled = false;
    const showDefaultOption = false;
    const [selectedValue, setSelectedValue] = useState(defaultValue);
    const DEFAULT_OPTION_VALUE = "any";
    const { t, i18n } = useTranslation('translation');
    const [availablePickupPoints, setAvailablePickupPoints] = useState<PickupPoint[]>([]);
    const [cartItems, setCartItems] = useState<CartItem[]>([]);

    const getAvailablePickupPointsForWebshopOrderOnInit = (items: CartItem[]): void => {
        getAvailablePickupPointsForWebshopOrder(items).then((result) => {
            if (result) {
                setAvailablePickupPoints(result.data as PickupPoint[]);
            }
        });
    };

    const getCartItemsOnInit = (): void => {
        getCartItems().then((result) => {
            if (result) {
                setCartItems(result as CartItem[]);
            }
        });
    };

    useEffect(() => {
        getCartItemsOnInit();
    }, [])

    useEffect(() => {
        getAvailablePickupPointsForWebshopOrderOnInit(cartItems);
    }, [cartItems])

    const handleChange = (event: SelectChangeEvent<string>) => {
        const id = event.target.value;
        setSelectedValue(event.target.value);
        if (id) {
            pluginStore.dispatchEvent(new PluginEvent("SelectedPickupPointChanged", { id: id }));
            localStorage.setItem('pickupPointsFilter', id as string);
            return;
        }
    };

    return (
        <div>
            {availablePickupPoints.length > 0 &&
                <div className={styles.pickup_points_container}>
                    <div className={styles.label}>{t("sellingSessions.label")}</div>
                    <div className={styles.select_wrapper}>
                        <Box className={styles.container}>
                            <FormControl disabled={disabled}>
                                <Select
                                    value={selectedValue as string}
                                    onChange={handleChange}
                                    className={styles.select}
                                    renderValue={(value) =>
                                        value === DEFAULT_OPTION_VALUE
                                            ? t("pickupPointsFilters.any")
                                            : availablePickupPoints.find((pickupPoint: PickupPoint) => pickupPoint.id == value)?.name
                                    }
                                >
                                    {showDefaultOption && (
                                        <MenuItem key="default" value={DEFAULT_OPTION_VALUE} className={styles.menuItem}>
                                            <Typography variant="subtitle1">{t('pickupPointsFilters.any')}</Typography>
                                        </MenuItem>
                                    )}
                                    {Array.isArray(availablePickupPoints) && availablePickupPoints.map((pickupPoint: PickupPoint) => (
                                        <MenuItem key={pickupPoint?.id} value={pickupPoint?.id} className={styles.menuItem}>
                                            <div>
                                                <Typography variant="subtitle1">{pickupPoint?.name}</Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    {pickupPoint?.address?.streetName + " " + pickupPoint?.address?.buildingNumber}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    {pickupPoint?.address?.postalCode + " " + pickupPoint?.address?.city}
                                                </Typography>
                                            </div>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                </div>
            }
        </div>
    );
}